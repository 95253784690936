    "use strict";
    app.search = {
        _vars: {
            idCounter: 0,
            delim: String.fromCharCode(1)
        },
        getNextId: function() {
            app.search._vars.idCounter++;
            return '_sc_-' + app.search._vars.idCounter;
        },
        correctAdvancedFrom: function() {
            var $searchForm = $('#advanced-search-form');
            $searchForm.find('input[name=skuMode][value=""]').val('exact');
            if (app.url.get('skuMode') !== 'exact') {
                $searchForm.find('input[name=skuMode][value=part]').attr('checked', true)
            }
        },
        init: function($container) {
            var $topSearch = $('.top .div-search-field form.search-form input');
            if ($topSearch.length > 0) {
                $topSearch.focus(
                    function() {
                        $('body').addClass('udu__top-search-focused');

                    }
                ).blur(
                    function() {
                        $('body').removeClass('udu__top-search-focused');
                    }
                )
            }

            if (!$container) {
                $container = $('.attr-search-form');
            }
            if ($container.length === 0) {
                return false;
            }
            this.correctAdvancedFrom();
            $container.each(
                function() {
                    var $this = $(this);
                    var $form = $this.find('form');
                    if ($form.length === 0) {
                        $form = $this.closest('form');
                    }
                    if ($form.length === 0 || $form.hasClass('__done')) {
                        return true;
                    }
                    var $attrSearchForm = $form.find('.search-prod-attributes');
                    if ($attrSearchForm.length > 0 && !$attrSearchForm.hasClass('__done')) {
                        app.search.buildAttrBlock($container, $attrSearchForm);
                        return true;
                    }
                    $form.addClass('__done');
                    $form.submit(
                        function(e) {

                            //var $topFormSearch = $('.top form.search-form');
                            //var $advSearchForm = $('#advanced-search-form').find('form');
                            //if ($advSearchForm.length === 1 && $topFormSearch.length === 1) {
                                //$topFormSearch.find('input').attr('name', '');
                            //}
                            var $this = $(this);

                            app.events.trigger(
                                'udu_BEFORE-SEARCH-GO',
                                {
                                    $form: $form
                                }
                            );


                            var url = $this.attr('action');
                            var params = [];
                            var ops = {};
                            var uniqParams = {};
                            var $items = $this.find('input:checked:not(:disabled), input:not([type="radio"]):not([type="checkbox"]):not(:disabled), select:not(:disabled)');
                            $items.each(
                                function() {
                                    var $this = $(this);
                                    var name = $this.attr('name');
                                    var val = $this.val();
                                    var isAttrName = app.search._isAttrName(name);
                                    if ((!isAttrName && uniqParams[name]) || !val || !name || name.indexOf('[op]') > 0) {
                                        return true;
                                    }
                                    uniqParams[name] = true;
                                    if (name === 'udu_attPriceFrom') {
                                        uniqParams[name] = true;

                                        if (uniqParams['priceFrom']) {
                                            return true;
                                        }

                                        uniqParams['priceFrom'] = true;
                                        uniqParams['priceTo'] = true;

                                        params.push({name: 'priceFrom', val: val});
                                        params.push({name: 'priceTo', val: $this.data('value-to')});

                                        return true;
                                    }
                                    params.push({name: name, val: val});
                                    if (!ops[name] && isAttrName) {
                                        ops[name] = true;
                                        params.push({name: name.replace('[value]', '[op]'), val: 'like'});
                                    }
                                }
                            );
                            if (app.correctFinalSearchArray) {
                                params = app.correctFinalSearchArray(params);
                            }

                            var pageSize = app.url.get('pg_size');

                            if (pageSize) {
								params.push({name: 'pg_size', val: pageSize});
                            }

                            url = app.formURL(url.split('?')[0], params);

                            if (!app._vars.keys.ctrlKey) {
                                app.blockWindow(
                                    true,
                                    true,
                                    function() {
                                        document.location = url;
                                    }
                                );
                            } else {
                                var win = window.open(url, '_blank');
                                win.focus();
                            }
                            return false;
                        }
                    );
                    app.search.fillAttrSearchForm($container);
                }
            );
        },
        fillAttrSearchForm: function($container) {
            if (!$container) {
                $container = $('.attr-search-form');
            }
            if ($container.length === 0) {
                return false;
            }
            var tmp = String(decodeURI(String(document.location))).match(/([&?]attributes[^&]*=[^&]+)|([&?]inStockOnly[^&]*=1+)|([&?]outOfStockOnly[^&]*=1+)/g);
            if (tmp) {
                tmp.forEach(
                    function(str) {
                        //var tmp = str.replace(/[&?]/g, '').split('=');
						var tmp = str.replace(/([&?])|(#!)$/g, '').split('=');
                        var $item = $container.find('input[name="' + tmp[0] + '"][value="' + tmp[1] + '"], select[name="' + tmp[0] + '"]');
                        if ($item.length === 0) {
                            $item = $container.find('input[name="' + tmp[0] + '"][type="text"]');
                        }
                        if ($item.length === 0) {
                            return;
                        }
                        var val = tmp[1].replace(/\"/g, '');
                        if (val) {
                            var tp = $item.attr('type');
                            if (tp === 'checkbox') {
                                $item.prop('checked', true);
                                $item.closest('.attr-search-form-block').addClass('shown');
                            } else if (tp === 'radio') {
                                $container.find('input[name="' + tmp[0] + '"][type="radio"][value="' + val +'"]').prop('checked', true);
                                $item.closest('.attr-search-form-block').addClass('shown');
                            } else if (tp === 'text' || tp === 'hidden' || $item[0].tagName === 'SELECT') {
                                $item.val(val);
                                if (tp !== 'hidden') {
                                    $item.closest('.attr-search-form-block').addClass('shown');
                                }
                                if ($item[0].tagName === 'SELECT' || tp === 'text') {
                                    $item.addClass('udu__need-expand');
                                }
                            }
                        }
                    }
                )
            }

            var tmpParams  = app.url.getAllKeyed();
            if (tmpParams && tmpParams['priceFrom']) {
                var $priceItem = $container.find('input[name=udu_attPriceFrom], select[name=udu_attPriceFrom]');
                if ($priceItem.length > 0) {
                    $priceItem.each(
                        function() {
                            var $this = $(this);
                            var tagName = $this[0].tagName;
                            var tp = $this.attr('type');
                            if (tagName === 'SELECT') {
                                $this.val(tmpParams['priceFrom']);
                                $this.closest('.attr-search-form-block').addClass('shown');
                            } else if (tp === 'radio' && $this.val() === tmpParams['priceFrom']) {
                                $this.prop('checked', true);
                                $this.closest('.attr-search-form-block').addClass('shown');
                            }
                        }
                    )
                }
            }

            if (tmpParams['categoryId']) {
                $container.find('select[name=categoryId]').val(tmpParams['categoryId']);
            }

            // it's for the ability to uncheck all radiobuttons for additional searches
            $container.find('input[type=radio]:not([name=skuMode])').closest('label').mousedown(
                function() {
                    var $input = $(this).find('input');
                    $input.data('udu-need-uncheck', 'N');
                    if ($input.prop('checked')) {
                        $input.data('udu-need-uncheck', 'Y');
                    }
                }
            ).click(
                function() {
                    var $input = $(this).find('input');
                    if ($input.data('udu-need-uncheck') === 'Y') {
                        $input.prop('checked', false)
                    }
                }
            );

            $('.udu__search-attrs .multi-block').each(
                function() {
                    app.search.syncMultBlock($(this));
                }
            );
            $('.udu__search-attrs .multi-block input').change(
                function() {
                   var $container = $(this).closest('.multi-block');
                   app.search.syncMultBlock($container);
                }
            );

            $('input[name=inStockOnly]:not(._done), input[name=outOfStockOnly]:not(._done)').addClass('_done')
                .change(
                    function() {
                        var $this = $(this);
                        if ($this.prop('checked')) {
							var nm = $this.attr('name');
							$('input[name=' + nm + ']').prop('checked', true);
							if (nm === 'inStockOnly') {
								$('input[name=outOfStockOnly]').prop('checked', false);
                            } else {
								$('input[name=inStockOnly]').prop('checked', false);
                            }
                        }
                    }
                )
        },
        syncMultBlock: function($container) {
            var cnt = $container.find('input').length;
            var cntCh = $container.find('input:checked').length;

            $container.find('.sel-info').html(cntCh + ' / ' + cnt);
        },
        buildAttrBlock: function($container, $attrSearchForm) {
            var html = '<div class="udu__search-attr-wrapper"><div><a class="adv-search-expand"><span></span>Additional Search</a></div><div class="advanced-search-place"></div></div>';
            $attrSearchForm.html(html).addClass('__done');
            app.getInfo(
                'attr-search-form',
                {
                    withCats: $('.search-prod-attributes').hasClass('udu__with-cats') ? 'Y' : 'N'
                },
                function(html) {
                    var $place = $('.advanced-search-place');
                    $place.html(html);
                    var $searchLink = $('a.adv-search-expand');
                    var $span = $searchLink.find('span');
                    app.utils.effects.showIt($span);
                    app.events.trigger('udu_ATTR_SEARCH_FORM_DONE', {place: $place});

                    $searchLink.click(
                        function(e) {
                            e.preventDefault();
                            $searchLink.toggleClass('expanded');
                            $place.toggleClass('expanded');
                            app.utils.effects.showIt($span);
                        }
                    );

                    $place.find('.sel-tools div.select-all').click(
                        function() {
                            $(this).closest('.multi-block').find('input').prop('checked', true);
                        }
                    );

                    $place.find('.sel-tools div.clear').click(
                        function() {
                            $(this).closest('.multi-block').find('input').prop('checked', false);
                        }
                    );
                    var multCount = $place.find('.multi-block').length;
                    if ($place.find('.udu__search-attrs > li').length < 5 && multCount === 0) {
                        $attrSearchForm.addClass('udu__simple-mode');
                        $place.addClass('expanded');
                    } else {
                        $place.find('.attr-item.multichoice').addClass('udu__mult-count-' + multCount);
                    }
                    if ($place.find('input, select').length > 0) {
                        $attrSearchForm.addClass('udu__with-items');
                    }
                    app.search.init($container);
                    var needExpand = false;
                    if ($('.udu__auto-expand-attr-search-Y').length > 0 || $place.find('input:checked').length > 0 || $place.find('.udu__need-expand').length > 0 || $place.find('select option:selected[value]:not([value=""])').length > 0) {
                        needExpand = true;
                    }
                    if (needExpand) {
                        $place.addClass('expanded');
                    }
                },
                'udu__att-search-form_' + app.getLang()
            )
        },
        _isAttrName: function(name) {
            return name.indexOf('attributes[') === 0;
        },

        quickSearch: function($item, $searchPlaces, $container, parentSelector, callBack) {
            if (!parentSelector) {
                parentSelector = 'tr';
            }
            if (!$container) {
				$container = $searchPlaces.closest('table');
            }
            $item.keyup(
                function() {
                    var $that = $(this);
                    if (app.search._vars.quickSearchTimerId) {
                        clearTimeout(app.search._vars.quickSearchTimerId);
                    }
                    app.search._vars.quickSearchTimerId = setTimeout(
                        function() {
                            app.search._quickSearch($that, $searchPlaces, $container, parentSelector);
                            if (callBack) {
								callBack();
                            }
                        },
                        40
                    )

                }
            ).attr('maxlength', 40);
        },
        _delim: function() {
            return app.search._vars.delim;
        },
        _clearInterval: function() {
            clearInterval(app.search._vars._intervalId);
        },
        _makeLoading: function(need) {
            if (need) {
                $('.def-loc-wrapper:not(._simple-mode)').addClass('loading').find('table.def-loc-table').addClass('udu__loading-v2');
            } else {
				$('.def-loc-wrapper:not(._simple-mode)').removeClass('loading').find('table.def-loc-table').removeClass('udu__loading-v2');
            }
        },
        _quickSearch: function($this, $searchPlaces, $container, parentSelector) {
            app.search._clearInterval();
            var val = $this.val().toLowerCase();
			var searchItems = $this.data('udu-parent-items');
			if (!searchItems || $.isEmptyObject(searchItems)) {
			    var d = app.search._delim();
			    var rowCount = 0;
				searchItems = {};
				var extraSearchMode = $container.find('.qs__search-wrapper').length > 0;
				$searchPlaces.each(
				    function() {
				        var $this = $(this);
				        var $parent = $this.closest(parentSelector);
				        if ($parent.length === 0) {
				            return true;
                        }

                        var id = $parent.attr('id');
				        if (!id) {
				            id = app.search.getNextId();
				            $parent.attr('id', id);
                        }

                        if (!searchItems[id]) {
							rowCount++;
							searchItems[id] = {
							    $parent: $parent,
                                $sItems: [],
                                searhWords: ''
                            }
                        }

                        if (extraSearchMode && $this.find('.qs__search-wrapper').length > 0) {
							searchArr = [];
							$this.find('.qs__search-wrapper').find('.tbl__td-move-item.tbl__main-move-item, span.tbl__move-val')
                                .each(
                                    function() {
										searchArr.push($(this));
                                    }
                                )

                        } else {
				            var searchArr = [$this];
                        }

                        for (var i = 0; i < searchArr.length; i++) {
							var iniHtml = '';
							var $tmpInp = searchArr[i].find('input.udu__qsft-input-v1');
							if ($tmpInp.length > 0) {
								iniHtml = $tmpInp.val();
                            } else {
								iniHtml = searchArr[i].html();
                            }

							var iniHtml2 = iniHtml.toLowerCase();

							searchItems[id].$sItems.push({
								$item: searchArr[i],
								html: iniHtml,
								html2: iniHtml2,
                                inpCnt: $tmpInp.length
							});
							searchItems[id].searhWords += iniHtml2 + d;
                        }


                    }
                );
				$this.data('udu-parent-items', searchItems);
				if (rowCount < 500) {
					$('.def-loc-wrapper').addClass('_simple-mode');
                }
            }

			app.search._makeLoading(true);

            var resArr = {
                vis: [],
                hid:[]
            };

			for (var i in searchItems) {
			    if (val === '' || searchItems[i].searhWords.indexOf(val) >= 0) {
			        resArr.vis.push(searchItems[i]);
                } else {
					resArr.hid.push(searchItems[i]);
                }
           }

           var found = resArr.vis.length > 0;

			app.search._vars._intervalId = setInterval(
			    function() {
                    for (var i = 0; i < 700; i++) {
                        var info = resArr.vis.shift();
                        if (!info) {
                            break;
                        }
                        if (info.$parent.hasClass('hidden')) {
							info.$parent.removeClass('hidden');
                        }
                        for (var a in info.$sItems) {
                            if (info.$sItems[a].inpCnt === 0) {
								var html = info.$sItems[a].html;
								var idx = info.$sItems[a].html2.indexOf(val);
								if (idx >= 0) {
									info.$sItems[a].$item.html(html.substr(0, idx) + '<mark>' +html.substr(idx, val.length) + '</mark>' + html.substr(idx + val.length));
								} else if (info.$sItems[a].$item.html() !== html) {
									info.$sItems[a].$item.html(html);
								}
                            }
                        }
                    }
					for (i = 0; i < 700; i++) {
						info = resArr.hid.shift();
						if (!info) {
							break;
						}
						if (!info.$parent.hasClass('hidden')) {
							info.$parent.addClass('hidden');
						}
					}

					if (resArr.hid.length === 0 && resArr.vis.length === 0) {
                        app.search._clearInterval();
						app.search._makeLoading(false);
						if (found) {
							$container.removeClass('udu__no-result').closest('div').removeClass('udu__no-result-2');
						} else {
							$container.addClass('udu__no-result').closest('div').addClass('udu__no-result-2');
						}
                    }
                },
                20
            );
        }
    };