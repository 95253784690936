app.money = {
	_vars: {

	},
	init: function() {
		var $curBlock = $('.udu__exc-rates-v1');
		if ($curBlock.length === 1) {
			app.money.getRate(
				app.money.getCurCurrency(),
				function(data) {
					for (var i in data.rates) {
						$curBlock.find('.udu__exc-' + i).find('.udu__rate').html(Math.round(1000 * data.rates[i]) / 1000);
					}
				}
			);

			$curBlock.find('.udu__cur-click').click(
				function() {
					var $this = $(this);
					var cur = $this.data('cur');
					$curBlock.find('.udu__cur-click').removeClass('selected');
					console.log(cur);
					if (cur === app.getLData('udu-cur-exch-currency', true)) {
						cur = 'U--';
					} else {
						$this.addClass('selected');
					}
					app.money.setExchCurrency(cur);
				}
			);
			var cur = app.getLData('udu-cur-exch-currency', true);
			app.money.setExchCurrency(cur);
			if (cur) {
				$('.udu__exc-' + cur).find('.udu__cur-click').addClass('selected');
			}
		}
	},
	setExchCurrency: function(cur) {
		$('.udu__exch-cur-view').remove();
		$('.udu__with-exch-cur-view').removeClass('udu__with-exch-cur-view');
		if (!cur) {
			cur = app.getLData('udu-cur-exch-currency', true);
		}
		app.setLData('udu-cur-exch-currency', cur, true);
		app.money.getRate(
			app.money.getCurCurrency(),
			function(data) {
				if (data.rates[cur]) {
					var rate = data.rates[cur];
					// set for Product List
					$('.product-list2 .item .price > span.money').each(
						function() {
							var $this = $(this);
							var val = app.money.parse($this.html());
							var res = app.money.formatMoney(rate * val);
							$this.closest('.price').append('<span class="udu__exch-cur-view"><span class="udu__exc-name">' + cur + '</span><span class="udu__exc-val">' + res + '</span></span>')
							$this.closest('.item').addClass('udu__with-exch-cur-view');
						}
					);

					app.utils.effects.showIt('.udu__exch-cur-view');
					if (window.admin) {
						admin.assignEditor();
					}
				}
			}
		)
	},
	getCurCurrency: function() {
		if (app._vars._currentCurrency) {
			return app._vars._currentCurrency;
		}
		var url = String(document.location);
		if (url.indexOf('/cad/') >= 0) {
			return 'cad';
		} else if (url.indexOf('/usd/') >= 0) {
			return 'usd';
		} else if ($('.top a.currency.usd').length > 0) {
			return 'cad';
		} else if ($('.top a.currency.cad').length > 0) {
			return 'usd';
		}
	},
	dgCount: function() {
		var price = null;
		if (app.prodInfo) {
			price = app.prodInfo.priceExample;
		}
		if (!price && app.platformType !== 'DC') {
			price = app.getAllJsonParamByKey('udu__moneyFormat');
		}
		if (!price) {
			return 2;
		}
		price = price.replace(/[^\d\.,]/g, '').replace(/,/g, '.');
		var tmp = String(price).split('.');
		if (tmp.length < 2) {
			return 0;
		}
		return String(tmp.slice(-1)).length;
	},
	formatMoney: function (n, c, d, t) {
		if (c === '' || c === undefined) {
			c = app.money.dgCount();
		}
		var c = isNaN(c = Math.abs(c)) ? 2 : c,
			d = d == undefined ? "." : d,
			t = t == undefined ? "," : t,
			s = n < 0 ? "-" : "",
			i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
			j = (j = i.length) > 3 ? j % 3 : 0;

		return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
	},
	formatMoneyForInput: function(val) {
		if (!val) {
			return '';
		}
		return app.money.formatMoney(val, '', null, '@').replace(/@/g, '');
	},
	toDollar: function(money, nbsp, dollar) {
		if (app.platformType === 'DC') {
			dollar = '$'
		}
		if (!dollar && app._vars._currentCurrencySymbol) {
			dollar = app._vars._currentCurrencySymbol;
		}
		if (!dollar) {
			dollar = app.getAllJsonParamByKey('udu__moneySymbol');
		}
		if (!dollar && app.money.getCurCurrency() === 'inr') {
			dollar = '&#x20b9;';
		}
		if (!dollar && app.prodInfo && app.prodInfo.currencySymbol) {
			dollar = String(app.prodInfo.currencySymbol).replace('&nbsp;', '');
		}
		if (!dollar) {
			dollar = '$'
		}
		if (nbsp) {
			var sym = '&nbsp;';
		} else {
			sym = ' ';
		}
		if (app.lang.isEn()) {
			return dollar + app.money.formatMoney(money);
		} else {
			return app.money.formatMoney(money, '', ',') + sym + dollar;
		}
	},
	parse: function(money) {
		if (!money) {
			return '';
		}
		return String(money.replace(',', '.')).replace(/[^0-9\.]+/g, '');
	},
	getLKey: function (excCur) {
		if (!excCur || excCur !== 'USD') {
			excCur = 'CAD';
		}

		return 'udu-exch-rates-' + excCur.toLowerCase();
	},
	getRate: function(excCur, callBack) {
		var key = app.money.getLKey(excCur);
		var data = app.getLData(key);
		var tm = + new Date();
		if (!data || !data.uduTime || ((tm - data.uduTime) / (60 * 60 * 1000)) > 3) {
			app.money.updateRates(
				excCur,
				callBack
			);
		} else {
			callBack(data);
		}
	},
	updateRates: function(excCur, callBack) {
		excCur = String(excCur).toUpperCase();
		if (!excCur || excCur !== 'USD') {
			excCur = 'CAD';
		}
		$.ajax({
			url: "https://esmanagedev.uducat.com/51/7a2ae57bc71a09d8b4163260fc8d70a5/en/cad/pages/udu-currency.html?excCur=" + excCur,
			dataType: "jsonp",
			type: "POST",
			data: {

			},
			contentType: "application/json; charset=utf-8",
			success: function (result, status, xhr) {
				var base = result.base;
				result.uduTime = + new Date();

				app.setLData(app.money.getLKey(excCur), result);
				if (callBack) {
					callBack(result);
				}
			},
			error: function (xhr, status, error) {
				console.log("Result: " + status + " " + error + " " + xhr.status + " " + xhr.statusText)
			}
		});
	},
	trunc: function(val, num) {
		if (!num || num === 1) {
			return val;
		}
		return Math.trunc(val / num) * num;
	},
	round: function(val, num) {
		if (!num || num === 1) {
			return val;
		}
		return Math.round(val / num) * num;
	}
};