app.utils.unlinkArr = function(arr) {
	return JSON.parse(JSON.stringify(arr));
};

app.utils.getUduBody = function(str, uduAddKey) {
	var delim = app._vars.uduDelim;

	if (uduAddKey) {
		delim = delim.replace('-->', '_' + uduAddKey + '-->');
	}

	if (!str || str.indexOf(delim) < 0) {
		return null;
	}
	return str.split(delim)[1];
};

app.utils.file = {
	ext: function(fileName) {
		if (!fileName) {
			return '';
		}
		return fileName.split('.').pop().toLowerCase();
	},
	baseName: function(fileName) {
		if (!fileName) {
			return '';
		}
		return String(fileName).replace(/\\/g, '/').split('/').pop();
	},
	getPageUrlByName: function(fileName) {
		return app.ajaxProcessURLs.process.replace('/ajax-process.html', '/' + fileName);
	}
};

app.ie = {
	ieIsOld: function() {
		return document.all || window.msCrypto;
	},
	showOldBrowserError: function() {
		if (app.ie.ieIsOld() && app.getLData('showOldBrowserError') !== 'N') {
			app.alert(
				'This website does not fully support Internet Explorer 11 (and earlier) versions. Please upgrade to Microsoft Edge or an equivalent modern web browser.',
				function() {
					app.setLData('showOldBrowserError', 'N')
				}
			);
		}
	}
};

$(
	function() {
		if (app._vars.dispOldBrowserMessage !== 'N') {
			app.ie.showOldBrowserError();
		}
	}
);

/**
 * Initialize messages to be used in system
 *
 * @param {string} inMessages
 * @param {function} callBack
 * @returns {void}
 */
app.initializeMessages = function(inMessages, callBack, lang) {
	lang = lang || app.getLang();
	var that = this;
	var wrd = '';
	var i = 0;
	var savedMessages = this.getLData('savedLangMessages_' + lang, true);
	if (!savedMessages) {
		savedMessages = {};
	}
	if (window.admin) {
		savedMessages = {};
	}
	if (inMessages) {
		if (!Array.isArray(inMessages)) {
			var tmp = inMessages.split(',');
		} else {
			tmp = inMessages;
		}

		for (i = 0; i < tmp.length; i++) {
			if (tmp[i]) {
				if (lang === app.getLang()) {
					that.messages[tmp[i]] = null;
				} else {
					if (!savedMessages[tmp[i]]) {
						wrd += tmp[i] + ',';
					}

				}
			}
		}
	}
	if (!savedMessages) {
		savedMessages = {};
	} else if (lang === app.getLang()) {
		for (i in savedMessages) {
			that.messages[i] = savedMessages[i];
		}
		for (i in that.messages) {
			if (!that.messages[i]) {
				wrd += i + ',';
			}
		}
	}

	wrd = wrd.slice(0, -1);
	if (wrd) {
		$.get(
			app.lang.getUrl(app.ajaxProcessURLs.process, lang),
			{
				op: 'read-messages',
				wrd: wrd
			},
			function(res) {
				try {
					var data = $.parseJSON(res);
				} catch(e) {
					that.asAjax(true);
					$.get(
						String(document.location),
						{
							op: 'read-messages',
							wrd: wrd
						},
						function(res) {
							that.asAjax(false);
							res = res.split('<!--AJAX_MODE_MSG-->');
							if (res && res[1]) {
								try {
									data = $.parseJSON(res[1]);
									that._savedLangMessages (data, savedMessages, callBack, lang);
								} catch(e) {
									return false;
								}
							}
						}
					);
					return false;
				}

				that._savedLangMessages (data, savedMessages, callBack, lang);
			}
		)
	} else {
		if (callBack) {
			callBack(savedMessages, lang);
		}
	}
};

/**
 * Sets local data for messages
 *
 * @param {{}} data
 * @param {{}} savedMessages
 * @param {function} callBack
 * @private
 */
app._savedLangMessages = function(data, savedMessages, callBack, lang) {
	lang = lang || app.getLang();
	if (data && data.data) {
		for (var i in data.data) {
			var val = this.urlDecode(data.data[i]);
			if (lang === app.getLang()) {
				this.messages[i] = savedMessages[i] = val;
			} else {
				savedMessages[i] = val
			}
		}
	}
	this.setLData('savedLangMessages_' + lang, savedMessages, true);
	if (callBack) {
		callBack(savedMessages, lang);
	}
};