$(
	function() {
		app.assignNumberControl();
		app.setSelectedLink();
		app.correctPriceTables();
		app.initializeScrollButton();
		app.assignProductOver();
		app.checkLearningCenter();
		app.setBackUrls();
		
		$("._ .social-buttons").animate(
			{
				marginLeft: -2
			}
		);				
		
		$("._ .social-buttons a")
			.mouseover (
				function() {
					$("._ .social-buttons a").not(this).stop().fadeTo(400, 0.6);
					$(this).stop().fadeTo(200, 1);
				}
			)
			.mouseout(
				function() {
					$("._ .social-buttons a").stop().fadeTo(800, 1);
				}
			)				
	}
);

var app = {

};