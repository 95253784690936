"use strict";

	$(
		function() {
			app.initializeJSFunctionality();
		}
	);

	if (!window.app) {
		var app = {};
	}
	if (!app.messages) {
		app.messages = {};
	}

	app.platformType = 'DC';
	if (!app._vars) {
        app._vars = {};
	}
	app.udu_generalMessages = 'templateDir';
	app._vars.uduDelim = '<!--UDU-INCL-DLM:--bb4df1ea-1ab7-4e5e-87f6-366e53ee6892-05cec099f850-->';

	/**
	 * Sets Product List View Type - List/Grid
	 *
	 * @param {number} inType
	 * @returns {void}
	 */
	app.setViewType = function(inType) {
		if (app.changeViewTypeProcess) {
			return;
		}
		app.changeViewTypeProcess = true;
		var className = inType == 1 ? 'grid' : 'line';
		$('.list-grid li').removeClass('selected');
		$('.list-grid .' + className).addClass('selected');
		app.createCookie('categoryViewType', className, 999);
		$('.product-list2')
			.fadeTo(
			300,
			0,
			function() {
				app.changeViewTypeProcess = false;
				$(this)
					.removeClass('grid line')
					.addClass(className)
					.fadeTo(300, 1);
			}
		)
	};

	/**
	 * Returns top window
	 *
	 * @returns {{}}
	 */
	app.top = function() {
		var wnd = window;
		try {
			for (var i = 0; i < 6; i++) {
				if (wnd !== wnd.parent && (wnd.parent.app || wnd.parent.admin)) {
					wnd = wnd.parent;
				} else {
					break;
				}
			}
		} catch(e) {

		}
		return wnd;
	};

	/**
	 * Returns Message By Code
	 *
	 * @param {string} inCode
	 * @returns {string}
	 */
	app.getMsg = function(inCode) {
		if (this.messages[inCode]) {
			return this.messages[inCode];
		}
		return inCode;
	};

	/**
	 * Creates Cookies for template
	 *
	 * @param {string} name
	 * @param {string} value
	 * @param {number} days
	 * @returns {void}
	 */
	app.createCookie = function(name, value, days) {
		var expires = "";
		if (days) {
			var date = new Date();
			date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
			expires = "; expires=" + date.toGMTString();
		}
		document.cookie = name + "=" + value + expires + "; path=/";
	};

	/**
	 * @param {number} wd
	 * Corrects links URL in case we have different links for Mobile and PC version
	 */
	app.correctMobileLinks = function(wd) {
		if (!wd) {
			wd = $(window).width();
		}

		if (wd > 760) {
			var tp = 'pc';
		} else {
			tp = 'mobile';
		}

		if (this._vars.lastMobileLinksType !== tp) {
			this._vars.lastMobileLinksType = tp;
			$('a[data-udu-mob-url]:not([data-udu-mob-url=""])').each(
				function() {
					var $item = $(this);
					if (!$item.data('udu-pc-url')) {
						$item.data('udu-pc-url', $item.attr('href'));
					}
					if (tp === 'pc') {
						$item.attr('href', $item.data('udu-pc-url'));
					} else {
						$item.attr('href', $item.data('udu-mob-url'));
					}
				}
			)
		}
	};

	/**
	 * Reads parameter from Cookie
	 *
	 * @param {string} name
	 * @returns {string}
	 */
	app.readCookie = function (name) {
		var nameEQ = name + "=";
		var ca = document.cookie.split(';');
		for(var i=0;i < ca.length;i++) {
			var c = ca[i];
			while (c.charAt(0) == ' ') c = c.substring(1,c.length);
			if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
		}
		return null;
	};

	/**
	 * Sets page size - for Product List (when user change it in DropDown)
	 *
	 * @param {number} pgSize
	 * @returns {void}
	 */
	app.setPageSize = function(pgSize) {
		app.setUrlParams(
			{
				pg_size : pgSize
			}
		)
	};

	/**
	 * Decodes urlencode string from Server part
	 *
	 * @param {string} str
	 * @returns {string}
	 */
	app.urlDecode = function(str) {
		return decodeURIComponent((str + '').replace(/\+/g, '%20'));
	};

	/**
	 * Makes URL by URL and params
	 *
	 * @param url
	 * @param params
	 * @returns {*}
	 */
	app.makeURL = function(url, params) {
		if (!url) {
			url = String(document.location);
			return url;
		}

		if (Array.isArray(params)) {
			var tmp = {};
			for (var i = 0; i < params.length; i++) {
				var info = params[i];
				tmp[String(params[i].name)] = params[i].val;
			}
			params = tmp;
		}

		var urlArr = url.split('#')[0].split('?');
		var arr = [];
		var get = '';

		if (urlArr[1]) {
			arr = urlArr[1].split('&');
		}
		for (i = 0; i < arr.length; i++) {
			tmp = arr[i].split('=');
			if (!params.hasOwnProperty(tmp[0]) && tmp[0]) {
				params[tmp[0]] = tmp[1];
			}
		}
		for (i in params) {
			if (params[i] !== '') {
				get += i + '=' + params[i] + '&';
			}
		}
		if (get) {
			return urlArr[0] + '?' + get.slice(0, -1);
		} else {
			return urlArr[0];
		}
	};

	/**
	 * Sets URL params as Get String and assigns it to document.location
	 *
	 * @param {{}} params
	 * @returns {void}
	 */
	app.setUrlParams = function(params) {
		var urlArr = String(document.location).split('?');
		var arr = [];
		var get = '';

		if (urlArr[1]) {
			arr = urlArr[1].split('&');
		}

		for (var i = 0; i < arr.length; i++) {
			var tmp = arr[i].split('=');
			if (!params.hasOwnProperty(tmp[0]) && tmp[0]) {
				params[tmp[0]] = tmp[1];
			}
		}

		for (i in params) {
			if (params[i] !== '') {
				get += i + '=' + params[i] + '&';
			}
		}
		if (get) {
			document.location = urlArr[0] + '?' + get;
		} else {
			document.location = urlArr[0];
		}
	};

	/**
	 * Checks if Learning Center is available
	 *
	 * @returns {void}
	 */
	app.checkLearningCenter = function() {
		if ($('.learn-center').length === 0) {
			return;
		}
		if (!app.readCookie('showLearningCenter')) {
			$.get(
				'/LC/Video_Get.htm',
				function(data) {
					try {
						var arr = eval ('(' + data + ')');
						if (arr.meta.recordsFound > 0) {
							app.createCookie('showLearningCenter', 'Y');
							$('.learn-center').removeClass('hidden');
						} else {
							app.createCookie('showLearningCenter', 'N');
						}
					} catch(e) {
						app.createCookie('showLearningCenter', 'N');
					}
				}
			);
		}
	};

	/**
	 * Shows Social buttons and assigns mouseover and mouseout for links there
	 *
	 * @param {{}} animateParams
	 * @returns {void}
	 */
	app.makeSocialButtons = function(animateParams) {
		if (animateParams) {
			var selector = ".social-buttons div.soc-wrapper";
			if (animateParams.animateSelector) {
				selector = animateParams.animateSelector;
				delete animateParams.animateSelector;
			}
			$(selector).animate(animateParams, 1000);
		}
		$("._ .social-buttons a")
			.mouseover (
			function() {
				$("._ .social-buttons a").not(this).stop().fadeTo(400, 0.5);
				$(this).stop().fadeTo(200, 1);
			}
		).mouseout(
			function() {
				$("._ .social-buttons a").stop().fadeTo(800, 1);
			}
		)
	};

	/**
	 * Initialize Floating Header
	 *
	 * @returns {void}
	 */
	app.initFloatingHeader = function(params) {
		if (this.isMobile()) {
			return;
		}
		var that = this;
		var h = $(params.floatSelector).height();

		if (!params.noTopFixDiv) {
			$('._').before('<div style="height:' + h + 'px" class="top-fix"></div>');
		}
		var fixTop = h - params.floatFix;
		if (fixTop) {
			this.fixTop = fixTop;
		}

		$("<style type='text/css'> ._ .fix {top:-" + this.fixTop + "px}</style>")
			.appendTo("head");

		$( window ).scroll(
			function(){
				that.floatingHeader(params.floatParams);
			}
		);
		this.floatingHeader(params.floatParams);
	};

	/**
	 * Controls scrolling to make fixed header
	 *
	 * @returns {void}
	 */
	app.floatingHeader = function(params) {
		var $top = $("._ > .top");
		var scroll = $(document).scrollTop();
		if(scroll > app.fixTop) {
			if (!$top.hasClass('fix')) {
				$top.addClass('fix');
				$(".top-fix").addClass('visible');
				if (params && params.onSetFix) {
					params.onSetFix(scroll);
				}
			}
		} else {
			if ($top.hasClass('fix')) {
				$top.removeClass('fix');
				$(".top-fix").removeClass('visible');
				if (params && params.onUnSetFix) {
					params.onUnSetFix(scroll);
				}
			}
		}
		if (params && params.onScroll) {
			params.onScroll(scroll);
		}
	};

	/**
	 * Sets URL in cookies for last Product List Page and Blog List
	 *
	 * @returns {void}
	 */
	app.setBackUrls = function() {
		if ($('._ .product-list2').length > 0) {
			app.createCookie('categoryBackUrl', document.location);
		}
		if ($('body').hasClass('blog-list')) {
			app.createCookie('blogBackUrl', document.location);
		}
	};

	/**
	 * Returns current language fr/en
	 *
	 * @returns {string}
	 */
	app.getLang = function() {
		if (this._currentLang) {
			return this._currentLang;
		}
		return String(document.location).indexOf('/fr/') > 0 ? 'fr' : 'en';
	};

	/**
	 * Initialize Tree (left menu)
	 *
	 * @returns {void}
	 */
	app.initializeTree = function() {
		var $a = $("._ .main .center-part div.li-block li a");
		this.setSelectedLink();
		var html = "<div class=\"node\"></div>";
		if (!this.treeOptions) {
			this.treeOptions = {};
		}
		if (this.treeOptions.colorizedNode) {
			html = "<div class=\"node\"><div></div></div>";
			$a.append('<span class="li-dot"></span>');
		}
		$a.each (
			function() {
				var arr = $(this).closest('li').find('li');
				if (arr.length > 0) {
					$(this).addClass('tree-node');
					$(html)
						.appendTo(this)
						.bind(
						'mousedown',
						function() {
							if (!$(this).hasClass('opened')) {
								$(this).addClass('opened');
								$(this).closest('li')
									.addClass('opened')
									.find('> ul')
									.hide()
									.slideDown();
							} else {
								$(this).removeClass('opened');
								$(this).closest('li')
									.find('> ul')
									.slideUp();
							}
							return false;
						}
					).bind(
						'click',
						function(e) {
							e = e || window.event;
							if (e.stopPropagation) {e.stopPropagation()} else {e.returnValue = false}
							e.cancelBubble = true;
							return false;
						}
					)
				}
				if ($(this).closest('li').hasClass('selected')) {
					$(this).addClass('selected');
					var tmp = $(this).parents('li');
					tmp.addClass('opened');
					while (tmp.length > 0) {
						$(tmp[0]).find('>div>a').find('div').addClass('opened');
						tmp = $(tmp[0]).parents('li');
						tmp.addClass('opened');
					}
				}
			}
		)
	};

	/**
	 * Sets selected class to menu links (top and left parts)
	 *
	 * @param {bool} withoutGet
	 * @returns {void}
	 */
	app.setSelectedLink = function(withoutGet) {
		var url = String(document.location);

		if (withoutGet) {
			url = url.split('?')[0];
		}

		var ln  = 0;
		var lst = null;
		$("._ .main .center-part div.li-block li a, ._ .menu li a").each (
			function() {
				if (url.indexOf(this.href) >= 0 && String(this.href).length > ln) {
					if ($(this).hasClass('menu-home') && this.href != url) return;
					ln = String(this.href).length;
					if (lst) {
						$(lst).removeClass('selected')
							.closest('li')
							.removeClass('selected');
					}
					lst = this;
					$(this).addClass('selected')
						.closest('li')
						.addClass('selected');
				}
			}
		);
		if (!withoutGet && $("._ .main .center-part div.li-block li.selected a, ._ .menu li.selected").length === 0) {
			app.setSelectedLink(true);
		}
	};

	/**
	 * Assign additional processing to input item - to enter Numbers only
	 *
	 * @returns {void}
	 */
	app.assignNumberControl = function() {
		$("input.number, td.quantity input").bind('keyup',
			function() {
				if ($(this).removeClass('is-filled').val() === '') {
					return ;
				}
				if (!Number(this.value) || Number(this.value) < 0) {
					$(this).val(0);
				} else {
					if ($(this).val() !== parseInt($(this).val())) {
						$(this).val(parseInt($(this).val()));
					}
				}
				if (Number($(this).val())) {
					$(this).addClass('is-filled');
				}
			}
		).attr('maxlength', 6)
			.css('text-align', 'center');
	};

	/**
	 * Saves some information about Product List - to be used to navigation to the latest
	 * visited product list and create prev/next buttons
	 *
	 * @returns {void}
	 */
	app.saveProductListInfo = function() {
		if (app.prodInfo) {
			return false;
		}
		var lName = 'udu-product-list-info';
		var listData = this.getLData(lName);
		if (listData) {
			var $a = $('a.continue');
			if ($a.length > 0 && listData.url) {
				$a.attr('href', listData.url);
			}
		}
		var $items = $('.product-list2 .name a');
		if ($items.length > 0) {
			var data = {};
			$items.each(
				function() {
					var url = $(this).attr('href');
					var $item = $(this).closest('.item');
					data[url.split('/').pop()] = {
						url: url,
						name: $(this).html(),
						img: $item.find('.image img').attr('src')
					};
				}
			);
			this.setLData(
				lName,
				{
					data: data,
					url: String(document.location),
					title: $('h1').html()
				}
			)
		}
	};

	/**
	 * Defines if we call form in AJAX Mode
	 *
	 * @param {bool} isAjax
	 * @returns {void}
	 */
	app.asAjax = function(isAjax) {
		var that = this;
		if (isAjax) {
			clearTimeout(this._ajaxTimeoutId);
			this.createCookie('udu_ajaxMode', 'Y');
		} else {
			this._ajaxTimeoutId = setTimeout(
				function() {
					that.createCookie('udu_ajaxMode', 'N');
				},
				100
			)
		}
	};

	app.utils = {
		setTimeout: function(callBack, timeOut, id) {
			if (!id) {
				id = '__generalTimeOut';
			}
			app._vars._timerIds = app._vars._timerIds || {};
			clearTimeout(app._vars._timerIds[id]);
			app._vars._timerIds[id] = setTimeout(
				function () {
					callBack();
				},
				timeOut
			);
		},
        disableEmptyLinks: function() {
            $('._').find('a:not([href]), a[href=""]').addClass('udu__empty-link').click(
                function(e) {
                    e.preventDefault();
                }
            )
        },
		str: {
			capitalize: function(str) {
				if (!str) {
					return '';
				}
				return str.charAt(0).toUpperCase() + str.slice(1);
			},
			stripTags: function(str) {
				return String(str).replace(/(<([^>]+)>)/gi, "");
			},
			unescape: function(str) {
				var txt = document.createElement("TEXTAREA");
				var res = $(txt).html(str).text();
				$(txt).remove();
				return res;
			},
			escape: function(str) {
				if (!str) {
					return '';
				}
				return str.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;").replace(/'/g, "&#039;");
			},
			clear: function(str, escapeToSpecialSymbols) {
				if (!str) {
					return '';
				}
				if (escapeToSpecialSymbols) {
					str = str.replace(/"/g, '&quot;');
				}
				return $.trim(str.replace(/<[^<]*>/g, ' ').replace(/["]+/g, ' ').replace(/ +/g, ' '))
			},
			getProdId: function(str) {
				if (!str) {
					return null;
				}
				var tmp = String(str).match(/-([\d]+)\.html$/);
				if (tmp && tmp[1]) {
					return Number(tmp[1]);
				}
				return null;
			},
			formatBytes: function(bytes, decimals) {
				if (!decimals) {
					decimals = 2;
				}
				if (bytes === 0) {
					return '0 Bytes'
				}
				var k = 1024;
				var dm = decimals < 0 ? 0 : decimals;
				var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
				var i = Math.floor(Math.log(bytes) / Math.log(k));
				return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
			}
		}
	};

	app.scriptUtils = {
		reg: function(id, callBack) {
			if (!app._vars._scriptCallBacks) {
				app._vars._scriptCallBacks = {}
			}
			if (!app._vars._scriptCallBacks[id]) {
				app._vars._scriptCallBacks[id] = {
					loaded: false,
					calls: []
				};
			}
			if (callBack) {
				app._vars._scriptCallBacks[id].calls.push(callBack);
			}
		},
		run: function(id, data, force) {
			app.utils.setTimeout(
				function() {
					var info = app._vars._scriptCallBacks[id];
					if (force) {
						info.loaded = true;
					}
					if (info.loaded) {
						for (var i  = 0; i < info.calls.length; i++) {
							if (info.calls[i]) {
								info.calls[i](data);
								info.calls[i] = null;
							}
						}
					}
				},
				10,
				'tmr_' + id
			)
		}
	};

	/**
	 * Generates UNIQUE KEY
	 *
	 * @returns {string}
	 */
	app.guid = function() {
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
			var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
			return v.toString(16);
		});
	};

	/**
	 * Gets info by Specific Code
	 * @param {number|string} opType
	 * @param {{}} params
	 * @param {function} callBack
	 */
	app.getInfo = function(opType, params, callBack) {
		var that = this;
		var postData = {};
		for (var i = 1; i < arguments.length; i++) {
			if (typeof arguments[i] === 'function') {
				callBack = arguments[i];
			} else if (typeof arguments[i] === 'object') {
				postData = arguments[i];
			}
		}
		postData.op = opType;
		if (postData.json) {
			delete postData.json;
			postData.toJSON = true;
		}
		$.post(
			app.ajaxProcessURLs.process,
			postData,
			function(data) {
				if (postData.toJSON) {
					try {
						data = $.parseJSON(data);
					} catch(e) {
						that.asAjax(true);
						$.post(
							String(document.location),
							postData,
							function(res) {
								res = res.split('<!--AJAX_MODE_MSG-->');
								if (res && res[1]) {
									if (callBack) {
										callBack($.parseJSON(res[1]));
									}
								}
							}
						);
						return  false;
					}
				}
				if (callBack) {
					callBack(data);
				}
			}
		);
	};


	/**
	 * Creates Prev/Next buttons
	 *
	 * @param {string=} createNextButton
	 * @returns {void}
	 */
	app.createPrevNextButtons = function(createNextButton) {
		if (!createNextButton) {
			createNextButton = 'Y';
		}
		var $prodDiv = $('div.product-view');
		if ($prodDiv.length === 0) {
			return;
		}
		app.__createNextButton = createNextButton;
		app.getScript(
			[
				'https://cdn.uducat.com/static/esb/udu-css/css/style-prod-detail.css',
				'https://cdn.uducat.com/static/esb/js/main.prod.detail.js'
			],
			null,
			{
				isLoaded: app.prodDetail
			}
		);
	};

	/**
	 * Corrects TD width for Prices table in Product Detail - to look better
	 *
	 * @returns {void}
	 */
	app.correctPriceTables = function() {
		for(var x = 0; x < 100; x++) {
			var $arr = $("table.price-table th.pos-" + x);
			if ($arr.length === 0) {
				break;
            }
			$arr.width(Math.max.apply(Math, $arr.map(function(){return $(this).width();}).get()));
		}
	};

	/**
	 * Initializes Scroll Button to got top by clicking
	 *
	 * @returns {void}
	 */
	app.initializeScrollButton = function() {
		$('body').append('<div id="back-top"></div>');
		$("#back-top").hide();
		$(function () {
			$(window).scroll(function () {
				if ($(this).scrollTop() > 100) {
					$('#back-top').fadeIn();
				} else {
					$('#back-top').fadeOut();
				}
			});
			$('#back-top').click(function () {
				$('body,html').animate({
					scrollTop: 0
				}, 800);
				return false;
			});
		});
	};

	/**
	 * Assigns over processing for Product List (when mouse over - it adds .over class to Prod Item)
	 *
	 * @param {{}} createListZoom
	 * @returns {void}
	 */
	app.assignProductOver = function(params) {
		if (!params) {
			params = {};
		}
		var selector = '._ .product-list2 div.item .image, .item-specials .image';
		$("._ .product-list2 div.item").mouseover(
			function() {
				$(this).addClass('over');
			}
		).mouseleave(
			function() {
				$(this).removeClass('over');
			}
		);

		if (window.appMobile && appMobile.isMobileMode && appMobile.isMobileMode()) {
			return;
		}

		if ($.fn.fancybox) {

			if (params.createListZoom) {
				$('<a class="list-zoom" href="#"></a>')
					.appendTo('._ .product-list2 div.item .image');
				$('.list-zoom').each(
					function() {
						$(this)
							.attr(
								'href',
								$(this).closest('.item').find('.image').find('img').data('original-image')
							)
						;
					}
				);
			}
			$('.list-zoom').each(
				function() {
					$(this)
						.attr(
							'href',
							$(this).closest('.image').find('img').data('original-image')
						);
				}
			);
			$('a.list-zoom, a.quick-view').fancybox({
				'transitionIn': 'elastic',
				'transitionOut': 'elastic'
			});
		}
	};

	/**
	 * Initializes index Slider
	 *
	 * @param {{}} options
	 * @returns {void}
	 */
	app.makeIndexSlider = function(options) {
		var that = this;
		if (typeof options === 'object') {
			var duration = options.duration;
			var slType = options.slType;
		} else {
			duration = options;
			options = {};
		}
		if (!Number(duration)) {
			duration = 5000;
		}
		if (!options.selector) {
			var $nivoSlider = $('.nivoSlider');
			this.iniIndexSliderHTML = $nivoSlider.html();
		} else {
			$nivoSlider = $(options.selector);
		}

		var linkCount = $nivoSlider.find('a').length;

		if (linkCount <= 1) {
			duration = 1000000000;
		}

		if ($.fn.nivoSlider) {
			$nivoSlider
				.nivoSlider({
					//effect: 'boxRandom,boxRain,boxRainReverse,slideInLeft,boxRainGrow,slideInRight',
					effect: 'sliceUpLeft,sliceUp,sliceDownLeft,sliceDown,boxRandom,boxRain,boxRainReverse,boxRainGrow,boxRainGrowReverse',
					prevText: '',
					nextText: '',
					pauseTime: duration
				});
		} else if ($.fn.bxSlider) {
			$nivoSlider.each(
				function() {
					var html = '<ul class="bxSlider">';
					$(this).find('> a').each(
						function() {
							var $this = $(this);
							var trg = $this.attr('target');
							if (trg) {
								trg = 'target="' + trg + '"'
							} else {
								trg = '';
							}

							var evtData = {$item: $this, html: $this.html()};
							app.events.trigger('udu_BEFORE-BX-SLIDER-ITEM-REBUILT', evtData);
							html += '<li><a class="nivo-imageLink" ' + trg + ' href="' + $this.attr('href') + '">' + evtData.html + '</a></li>';
						}
					);
					html += '</ul>';
					$(this)
						.addClass('bx-wrapper')
						.html(html);

					$('.li-nivo-wrapper')
						.addClass('li-bx-wrapper');
				}
			);

			var resp = true;
			if (slType === 'bx2') {
				resp = false;
			}

			var bxOptions = {
				pause: duration,
				auto: true,
				responsive: resp,
                touchEnabled: false
			};

			if (options.bxOptions) {
				for (var i in options.bxOptions) {
					bxOptions[i] = options.bxOptions[i];
				}
			}

			$('.bxSlider')
				.bxSlider(bxOptions);
			$('.bx-pager')
				.addClass('nivo-controlNav');
			$('._ .bx-prev')
				.addClass('nivo-prevNav');
			$('._ .bx-next')
				.addClass('nivo-nextNav');
			$('._ .bx-controls-direction')
				.addClass('nivo-directionNav');
		}

		if (linkCount <= 1 && !options.selector) {
			$('.nivo-controlNav, .nivo-directionNav').remove();
		}

		$('._ .li-nivo-wrapper, .nivoSlider')
			.fadeTo(0, 0)
			.css('visibility', 'visible')
			.fadeTo(1000, 1);

		if (slType !== 'bx' && $('body').hasClass('center-slider')) {
			$(window).resize(
				function() {
					that.setSliderCenter();
				}
			);
			this.setSliderCenter();
		}

		if (slType === 'bx') {
			$("<style type='text/css'>body ._ .nivoSlider, body ._ .slider-wrapper, " +
				"body ._ .bx-viewport, body .theme-default #slider, body ul.bxSlider > li > a {height: auto !important}</style>").appendTo("head");
		} else if (slType === 'bx2') {
			$("<style type='text/css'>.bx-wrapper img {max-width: 4000px !important}</style>").appendTo("head");
		}

		if (options._VERSION > 1) {
			return false;
		}

		this.createMobileSlider();
		if (app.responsiveMode) {
			$('.nivoSlider, .slider-wrapper, ul.li-nivo-wrapper').addClass('responsiveMode');
		}

	};

	/**
	 * Corrects Top Menu Popups - in case they are to long, so system will
	 * divide them to separate lines
	 *
	 * @param {{}} params
	 * @returns {void}
	 */
	app.correctTopMenuPopups = function(params) {
		var hideTime = 500;
		var maxPopupItemLength = 16;
		var ieVer = app.getInternetExplorerVersion();
		if (ieVer > 0 && ieVer < 9) {
			hideTime = 1;
		}
		$(document).click (
			function() {
				app.showMenuPopup(null);
			}
		);
		$('.menu, .my-cart-line').click(
			function(e) {
				e.stopPropagation();
			}
		);
		if (!params) {
			params = {};
		}
		if (params.maxPopupItemLength) {
			maxPopupItemLength = params.maxPopupItemLength;
		}
		if (!app.menuPopupWidth) {
			app.menuPopupWidth = 200;
		}
		if (params.mode == 'advanced') {
			$('._ .menu .delimiter').html('<div class="inner"></div>');
			$('._ .menu > ul > li.with-children > div.top-title').mouseenter(
				function() {
					var $li = $(this).closest('li');
					clearTimeout($li.data('udu-timer-id'));
					$li.find('> ul.ul-mega-1, > .top-title > .delimiter').show();
					$li.data(
						'udu-timer-id2',
						setTimeout(
							function() {
								$li.addClass('mouse-over closing-popup');
							},
							1
						)
					);
				}
			);
			$('._ .menu > ul > li').mouseleave(
				function() {
					var that = this;
					clearTimeout($(this).data('udu-timer-id2'));
					$(this).removeClass('mouse-over')
						.addClass('closing-popup')
						.data(
							'udu-timer-id',
							setTimeout(
								function() {
									$(that)
										.removeClass('closing-popup')
										.find('> ul.ul-mega-1,> .top-title > .delimiter')
										.hide();
								},
								hideTime
							)
						);
				}
			)
		}
		$('.menu li.with-children ul').each(
			function() {
				var $parent = $(this)
					.closest('li.with-children')
					.mouseenter (
					function() {
						if ($(this).hasClass('hover')) {
							return;
						}
						$(this).find('ul.ul-mega-2')
							.each(
							function() {
								$(this).css('left', $(this).closest('li').outerWidth() - 1);
							}
						);
					}
				);
				if ($(this).hasClass('cat-column')) {
					return;
				}
				var $li = $(this).find('> li');
				if($li.length > maxPopupItemLength) {
					var pos = 0;
					var itemPerColumn = maxPopupItemLength;
					if ($li.length < 45) {
						itemPerColumn = Math.ceil($li.length / 3);
					}
					var columnCount = Math.ceil($li.length / itemPerColumn);
					var html = '<ul class="multi-columns" style="width: ' + ((app.menuPopupWidth + 5) * columnCount) + 'px;">';
					$li.each(
						function() {
							if (pos == 0) {
								html += '<li style="padding:0 5px !important; border: none !important;background: transparent !important;width: ' + app.menuPopupWidth + 'px"><ul>'
							}
							if ($(this).hasClass('with-children')) {
								html += '<li class="with-children">' + $(this).html() + '</li>';
							} else {
								html += '<li>' + $(this).html() + '</li>';
							}
							pos++;
							if (pos >= itemPerColumn) {
								html += '</ul></li>';
								pos  =  0;
							}
						}
					);

					if ($li.length % itemPerColumn != 0) {
						html += '</ul></li>';
					}

					html += "</ul>";

					var ulClass = '';
					var elUl = $parent.find('> ul')[0];
					if (elUl) {
						ulClass = elUl.className;
					}

					$parent.find('ul').remove();
					$parent.append(html);
					$parent.find('> ul')
						.css('left', ($parent.outerWidth() - (app.menuPopupWidth + 5) * columnCount) / 2)
						.addClass(ulClass);

				}
			}
		);
		$('.menu ul').mouseover(
			function() {
				if (!$(this).hasClass('viewed')) {
					$(this).addClass('viewed');
				}
			}
		)
	};

	/**
	 * Sets local data in Local Storage
	 *
	 * @param {string} key
	 * @param {string} data
	 * @param {bool} onSessionLevel
	 * @returns {*}
	 */
	app.setLData = function (key, data, onSessionLevel) {
		if (!window.localStorage) {
			return data;
		}
		if (onSessionLevel) {
			window.sessionStorage[key] =  JSON.stringify(data);
		} else {
			window.localStorage[key] =  JSON.stringify(data);
		}
		return data;
	};

	/**
	 * Gets local data from Local Storage
	 *
	 * @param {string} key
	 * @param {bool} onSessionLevel
	 * @returns {*}
	 */
	app.getLData = function(key, onSessionLevel) {
		if (!window.localStorage) {
			return '';
		}
		if (onSessionLevel) {
			if (!window.sessionStorage[key]) {
				return '';
			}
			return  JSON.parse(window.sessionStorage[key]);
		} else {
			if (!window.localStorage[key]) {
				return '';
			}
			try {
				return  JSON.parse(window.localStorage[key]);
			} catch(e) {
				return window.localStorage[key];
			}
		}
	};

	/**
	 * Returns IE Version
	 *
	 * @returns {number}
	 */
	app.getInternetExplorerVersion = function() {
		var rv = -1;
		if (navigator.appName == 'Microsoft Internet Explorer') {
			var ua = navigator.userAgent;
			var re  = new RegExp("MSIE ([0-9]{1,}[\.0-9]{0,})");
			if (re.exec(ua) != null)
				rv = parseFloat( RegExp.$1 );
		}
		return rv;
	};

	/**
	 * Show/Hides menu items popups in the header menu by clicking
	 *
	 * @param {Object} btn
	 * @param {boolean=} forceHide
	 * @returns {void}
	 */
	app.showMenuPopup = function (btn, forceHide) {
		if (!forceHide) {
			$('.header .clicked, .my-cart-line .clicked, .ul-my-cart .clicked').each(
				function() {
					if (this != btn) {
						app.showMenuPopup (this, true);
					}
				}
			);
		}
		if (!btn) {
			return;
		}
		var $btn = $(btn);
		var $ul = $btn.closest('li').find('.ul-mega-1');
		if ($ul.hasClass('hidden') && !forceHide) {
			$ul.show()
				.removeClass('hidden')
				.stop()
				.fadeTo(300, 1)
				.closest('li')
				.addClass('hover with-children')
				.find('.delimiter')
				.removeClass('hidden');
			$btn.addClass('clicked');
		} else {
			$ul.hide()
				.stop()
				.addClass('hidden')
				.fadeTo(200, 0.01)
				.closest('li')
				.removeClass('hover')
				.find('.delimiter')
				.addClass('hidden');
			$btn.removeClass('clicked');
		}
	};

	/**
	 * Gets Cart Popup
	 *
	 * @returns {void}
	 */
	app.getCartHtml = function() {
		var $ul = $('.my-cart-popup');
		if ($ul.hasClass('loading') && !$ul.hasClass('busy')) {
			$ul.addClass('busy');
			$.get(
				app.ajaxProcessURLs.process,
				{
					op: 'get-cart'
				},
				function(data) {
					$ul.removeClass('loading')
						.find('li')
						.hide()
						.html(data)
						.fadeIn();

					$ul.find('tr').mouseover(
						function() {
							$(this).addClass('over');
						}
					)
				}
			)
		}
	};

	/**
	 * Corrects Main Slider position based on Screen Width and set necessary position for Pagination Links
	 *
	 * @returns {void}
	 */
	app.setSliderCenter = function() {

		if (!app.$leftSliderNav || app.$leftSliderNav.length == 0) {
			app.$leftSliderNav = $(".nivo-directionNav a.nivo-prevNav");
			app.$rightSliderNav = $(".nivo-directionNav a.nivo-nextNav");
			app.$leftSliderNav.data('udu-ini-left', parseInt(app.$leftSliderNav.css('left')));
			app.$rightSliderNav.data('udu-ini-right', parseInt(app.$rightSliderNav.css('right')));
			app.$nivoSlider = $('.nivoSlider');
			app.$nivoControlNav = $('.nivo-controlNav');
		}

		var marg = (1920 - $('._').width()) / 2;
		app.$nivoSlider.css ({
			marginLeft : -marg
		});

		var topPos = app.$nivoSlider.height() / 2 - app.$leftSliderNav.height() / 2;
		app.$leftSliderNav.css({
			left: Number(app.$leftSliderNav.data('udu-ini-left')) + marg,
			top: topPos
		});
		app.$rightSliderNav.css({
			right: Number(app.$rightSliderNav.data('udu-ini-right')) + marg,
			top: topPos
		});

		app.$nivoControlNav.css({
			paddingLeft: marg,
			paddingRight: marg
		});
	};

	/**
	 * Runs jCarousel for Index Page
	 *
	 * @param {{}} params
	 * @returns {void}
	 */
	app.runCarusel = function(params) {
		var html = '';
		var $carousel = $('ul.jcarousel-skin-index');
		if (!params){
			params = {};
		}
		if (!params.auto) {
			params.auto = 3;
		}

		var $sliderIni = $('#index-slider-ini');

		if ($sliderIni.length > 0) {
			$sliderIni.find('a').each(
				function() {
					var addCssClass = "";
					if ($(this).find('.nivo-add-html').length == 0) {
						addCssClass += 'empty-text';
					}
					if (!$(this).attr('href')) {
						addCssClass += ' no-link';
					}
					var target = $(this).attr('target');
					if (target && target != 'undefined') {
						target = 'target="' + target + '"';
					} else {
						target = '';
					}
					if (params.noEmptyLinks && !$(this).attr('href')) {
						html += '<li class="item ' + addCssClass + '">' +
							'<div class="image">' +
								$(this).html() +
							'</div>';
					} else {
						html += '<li class="item ' + addCssClass + '"><div class="image">' +
							'<a ' + target + ' href="' + $(this).attr('href') + '">' +
								$(this).html() +
							'</a>' +
							'</div>';
					}

					if (params.innerHTML) {
						html += params.innerHTML;
					}
					html +=	'</li>';
				}
			);
			$carousel.html(html);
		}

		$carousel.find('.image a').each(
			function() {
				var attr = String('target,href').split(',');
				var $link = $(this).closest('li.item')
					.find('a.linker');
				if ($link.length > 0) {
					for (var i = 0; i < attr.length; i++) {
						if ($(this).attr(attr[i]) && $(this).attr(attr[i]) != "undefined") {
							$link.attr(attr[i], $(this).attr(attr[i]));
						}
					}
				}
			}
		);
		this.iniCaruselHTML = $carousel.html();
		$carousel.jcarousel({
				wrap: 'circular',
				scroll: 3,
				auto: params.auto,
				create: $carousel.hover(function () {
						$(this).jcarousel({
							auto: 99999
						});
					},
					function () {
						$(this).jcarousel({
							auto: params.auto
						});
					})
			});
		this.createMobileSlider();
	};

	/**
	 * Creates necessary HTML for Mobile Sliders
	 *
	 * @returns {void}
	 */
	app.createMobileSlider = function() {
		if (window.appMobile && appMobile.isMobileMode && appMobile.isMobileMode() && appMobile.createMobileSlider) {
			appMobile.createMobileSlider();
		}
	};

	/**
	 * Deletes product from Cart by id and ask confirmation - html param
	 *
	 * @param {string} html
	 * @param {string} id
	 * @returns {void}
	 */
	app.deleteCartProduct = function(html, id) {
		app.confirm(
			html,
			function() {
				$('#cartItemKey-' + id)
					.closest('form')
					.submit();
			}
		)
	};

	/**
	 * Sets mask for Phone/Fax input items
	 *
	 * @param {string} inMask
	 * @returns {void}
	 */
	app.setPhoneMask = function(inMask) {
		$('#id_accountForm_telephone, #id_checkoutForm_telephone, #id_checkoutForm_fax, ' +
			'#id_accountForm_fax, #id_accountForm_billingTelephone, #id_accountForm_shippingTollFreeFax, ' +
			'#id_accountForm_shippingTelephone, #id_accountForm_billingTollFreeFax, ' +
			'#id_accountForm_shippingTollFreeTelephone, #id_accountForm_shippingFax, #id_accountForm_tollFreeFax, ' +
			'#shippingTollFreeFax, #id_accountForm_billingFax, #id_accountForm_tollFreeTelephone, ' +
			'#id_accountForm_billingTollFreeTelephone').each(
			function() {
				$(this).mask(inMask,
					{
						placeholder: "X"
					}
				);
				$(this).attr('placeholder', inMask.replace(/n/g, 'X').replace('?', '').replace(/a/g, 'A'));
			}
		);
	};

	/**
	 * Makes additional Registration Form Checking befor data sending
	 *
	 * @param {Object} inForm
	 * @returns {boolean}
	 */
	app.checkRegisterForm = function(inForm) {
		var formDataIsCorrect = true;
		var topErrY = null;
		$('.error-line').remove();
		$(inForm).find('tr.required').each(
			function() {
				var $tr = $(this);
				var $input = $tr.find('input, select');
				if ($input.attr('type') == 'password' && $input.attr('placeholder') == '************************') {
					return;
				}
				if ($input.val() == '') {
					formDataIsCorrect = false;
					$tr.before('<tr class="error-line"><td class="error message" colspan="2">' +
						'<div>' + app.messages.registerFormRequiredField + '</div></td>' +
						'</tr>'
					);
					if (!topErrY) {
						topErrY = Math.max($tr.offset().top - 200, 100);
					}
				}
			}
		);

		if (!formDataIsCorrect) {
			app.alert(app.messages.registerFormError);
			$('body,html').animate(
				{scrollTop: topErrY},
				800
			);
		}

		return formDataIsCorrect;
	};

	/**
	 * Initialize Scroll Button
	 * @returns {boolean}
	 */
	app.initScrollButton = function() {
		$('body').append("<div id=\"back-top\"></div>");
		var $backTop = $("#back-top").hide();
		$(function () {
			$(window).scroll(function () {
				if ($(this).scrollTop() > 100) {
					$backTop.fadeIn();
				} else {
					$backTop.fadeOut();
				}
			});
			$backTop.click(function () {
				$('body, html').animate({
					scrollTop: 0
				}, 800);
				return false;
			});
		});
	};

	/**
	 * Prints Product List on Preview Quote Page
	 *
	 * @returns {void}
	 */
	app.doPrintProductList = function() {

		$('div.printable-area').remove();
		$('body').append('<div class="printable-area"></div>');

		var $printArea = $('div.printable-area');
		var $h1 = $('.d1 h1, .center-part h1');

		var html = '<h1>' + $h1.html() + '</h1>';

		$('table.list tr').each(
			function() {
				html += '<table class="line"><tr>' + $(this).html() + '</tr></table>';
			}
		);

		$printArea.html(html);
		window.print();
	};

	/**
	 * Make checkout process for DC stores
	 *
	 * @returns {void}
	 */
	app.doCheckout = function() {
		try {
			$('#submit-form').submit();
		} catch(e) {

		}
	};

	/**
	 * Defines if it's modern browers
	 * @returns {boolean}
	 */
	app.goodBrowser = function() {
		if (this.isMobile()) {
			return false;
		}
		if (/Chrome/.test(navigator.userAgent) || navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
			return true;
		}
		return false;
	};

	/**
	 * Assigns necessary JS handlers to different elements in templates
	 * template code does not contain any JS
	 *
	 * @returns {void}
	 */
	app.initializeJSFunctionality = function() {
		this.makePopupLinks();
		if (!this.ajaxProcessURLs) {
			this.ajaxProcessURLs = top.app.ajaxProcessURLs;
		}
		if (!this.ajaxProcessURLs) {
			this.ajaxProcessURLs = {};
		}
		var that = this;
		$('.top .search-form input').change (
			function() {
				$(this).val($(this).val().toUpperCase());
			}
		);
		$('#submit-form, td.quantity form').submit(
			function() {
				app.blockWindow(true, true);
				$('#div-block').css('z-index', 99999);
			}
		);
		var $err = $('div.message.error');
		if ($err.length > 0) {
			var pos = $err.position();
			$('body, html').animate({
				scrollTop: pos.top
			}, 800);
		}
		if (this.goodBrowser()) {
			$('.user-register input[type="password"]')
				.val('')
				.attr('readonly', true)
				.focus(
					function() {
						$(this).attr('readonly', false);
					}
				).blur(
					function() {
						$(this).attr('readonly', true);
					}
				);
		}
		$('div[data-udu-messages],table[data-udu-messages]').each(
			function() {
				var wrd = $(this).data('udu-messages').split(',');
				for (var i = 0; i < wrd.length; i++) {
					if (!that.messages[wrd[i]]) {
						that.messages[wrd[i]] = null;
					}
				}
			}
		);
		var h = $('.warn-1 form input').outerHeight();
		if (Number(h)) {
			$("<style type='text/css'> " +
				".warn-1 form tr.error input {background-position: right " + ((h - 20) / 2) + "px center !important}" +
				"</style>")
				.appendTo("head");
		}
		$('form input').focus(
			function() {
				$(this).closest('tr.error')
					.removeClass('error');
			}
		);
		// general messages on template level
		var tmp = app.udu_generalMessages.split(',');
		for (var i = 0; i < tmp.length; i++) {
			if (!that.messages[tmp[i]]) {
				that.messages[tmp[i]] = null;
			}
		}
		// general window resize processing
        app.correctMobileLinks();
        $(window).resize(
            function() {
                app.correctMobileLinks();
            }
        );
		this.initializeMessages();
        $('form.user-register, div.customer-info form[action*=change-info]').addClass('_done');
	};

	/**
	 * Defines if we work in mobile mode
	 *
	 * @returns {boolean}
	 */
	app.isMobile = function() {
		if (window.appMobile && appMobile.isMobileMode && appMobile.isMobileMode()) {
			return true;
		}
		return false;
	};

	/**
	 * Processes JSON data - do nothing in DC for now - for compatibility with ES
	 */
	app.processAllJSON = function() {

	};
	/**
	 * Returns color for selected class name
	 *
	 * @param {string} className
	 * @returns {string}
	 */
	app.getClassColor = function(className) {
		var $div = $('<div class="' + className + ' hidden"></div>').appendTo('._');
		var color = $div.css('color');
		$div.remove();
		return color;
	};

	/**
	 * Returns dir where JS files for template are allocated
	 *
	 * @returns {string}
	 */
	app.getJSDir = function () {
		var path = '';
		$('script[src*="all.js"], script[src*="main.core.js"]').each(
			function () {
				path = $(this).attr('src').split('/main.core.js')[0].split('/all.js')[0];
				return false;
			}
		);
		return path;
	};

	/**
	 * Returns dir where CSS files for template are allocated
	 *
	 * @returns {string}
	 */
	app.getCSSDir = function () {
		var path = '';
		$('link[href*="style.css"], link[href*="all.css"]').each(
			function () {
				path = $(this).attr('href').split('/style.css')[0].split('/all.css')[0];
				return false;
			}
		);
		return path;
	};

	/**
	 * Performs Link Popup Functionality
	 */
	app.makePopupLinks = function() {
		var $items = $('a._udu-popup');
		if ($items.length > 0) {
			app.getScript(
				'main.link.popup.js',
				function() {
					app._makePopupLinks($items);
				}
			);
		}
	};

	/**
	 * Forms URL based on Params
	 *
	 * @param url
	 * @param params
	 * @returns {*}
	 */
	app.formURL = function (url, params) {
		var token = url.indexOf('?') > 0 ? '&' : '?';
		if (Array.isArray(params)) {
			for (var i = 0; i < params.length; i++) {
				url += token + params[i].name + '=' + encodeURIComponent(params[i].val);
				token = '&';
			}
		} else {
			for (i in params) {
				url += token + i + '=' + encodeURIComponent(params[i]);
				token = '&';
			}
		}
		return url;
	};

/**
 * Add JS script from JS folder to the page
 * @param {string|object} name
 * @param {function} callBack
 * @param {{}} params
 * @returns {boolean}
 */
app.getScript = function(name, callBack, params) {
	var that = this;
	if (name === 'fancy') {
		name =  [
			'fancybox/fancybox.min.css',
			'fancybox/fancybox.min.js'
		];
	}

	params = params || {};

	if (!params.backMd5) {
		params.backMd5 = app.utils.str.md5(name);
		app.scriptUtils.reg(params.backMd5, callBack);
	}

	if (params.isLoaded) {
		app.scriptUtils.run(params.backMd5, name, true);
		return true;
	}
	if (!params.guid) {
		params.guid = app.guid();
	}
	if (!app._vars._getScriptCalls) {
		app._vars._getScriptCalls = {};
	}
	if (params.block) {
		app.blockWindow(true);
	} else if (params.wait) {
		app.blockWindow(true, true);
	}
	if (!this._scripts) {
		this._scripts = {};
	}
	if ($.fn.fancybox) {
		this._scripts['fancybox/fancybox.min.js'] = true;
		this._scripts['fancybox/fancybox.min.css'] = true;
	}

	if (typeof name === 'object') {
		for (var i in name) {
			if (typeof name[i] === 'string') {
				params.__requiredFiles = name;
				app.getScript(name[i], callBack, params);
			}
		}
		return false;
	}

	var isCSS = String(name).endsWith('.css');
	var path = name;
	if (String(name).indexOf('//') >=0) {
		// do nothing
	} else if (String(name).indexOf('/') >= 0) {
		path = String(app.getJSDir().slice(0,-2) + name).replace(/\/+/g, '/');
	} else if (isCSS) {
		path = this.getCSSDir() + '/' + name;
	} else {
		path = this.getJSDir() + '/' + name;
	}

	if (!isCSS) {
		var jsSel = 'script[src$="/' + name + '"]';
		if (name.indexOf('//') >= 0) {
			jsSel = 'script[src$="' + name + '"]';
		}
		if ($(jsSel).length > 0 && !this._scripts.hasOwnProperty(name)) {
			this._scripts[name] = true;
		}
	} else {
		var csSel = 'link[href$="/' + name + '"]';
		if (name.indexOf('//') >= 0) {
			csSel = 'link[href$="' + name + '"]';
		}
		if ($(csSel).length > 0) {
			this._scripts[name] = true;
		}
	}

	if (this._requiredScriptsLoaded(name, params)) {
		if (!app._vars._getScriptCalls[params.guid]) {
			app.scriptUtils.run(params.backMd5, path, true);
		}
		app._vars._getScriptCalls[params.guid] = true;
		return true;
	} else if (this._scripts[name]) {
		return false;
	}

	if (isCSS) {
		if (String(name).indexOf('/') >= 0) {
			$("<link/>", {
				rel: "stylesheet",
				type: "text/css",
				href: path
			}).appendTo("head");
			app._scripts[name] = true;
			if (that._requiredScriptsLoaded(name, params) ) {
				app._vars._getScriptCalls[params.guid] = true;
				app.scriptUtils.run(params.backMd5, path, true);
			}
		} else {
			$.ajax({
				type: "GET",
				url: path,
				success: function(data) {
					var imgDir = app.getCSSDir().slice(0, -3) + 'img';
					data = data.replace(/\[imgDir\]/g, imgDir);
					app._scripts[name] = true;
					$("head").append("<style>" + data + "</style>");
					app.events.trigger('udu_CSS-LOADED', {'scriptName': name});
					if (that._requiredScriptsLoaded(name, params)) {
						app._vars._getScriptCalls[params.guid] = true;
						app.scriptUtils.run(params.backMd5, path, true);
					}
				},
				cache: true
			});
		}
	} else {
		app._scripts[name] = false;
		$.ajax({
			type: "GET",
			url: path,
			success: function() {
				app._scripts[name] = true;
				app.events.trigger('udu_JS-LOADED', {'scriptName': name});
				if (that._requiredScriptsLoaded(name, params) && !app._vars._getScriptCalls[params.guid]) {
					app._vars._getScriptCalls[params.guid] = true;
					app.scriptUtils.run(params.backMd5, path, true);
				}
			},
			dataType: "script",
			cache: true
		});
	}
	return true;
};

/**
 * Defines if all necessary scripts were loaded
 * @param {string} name
 * @param {Object} params
 * @returns {boolean}
 * @private
 */
app._requiredScriptsLoaded = function(name, params) {
    if (!params.__requiredFiles) {
        return this._scripts[name];
    } else {
        for (var i in params.__requiredFiles) {
            if (typeof params.__requiredFiles[i] === 'string' && !this._scripts[params.__requiredFiles[i]]) {
                return false;
            }
        }
    }
    return true;
};

	/**
	 * Returns right tab container
	 *
	 * @returns {Object}
	 */
	app.getRightTabWrapper = function() {
		var $div = $('._ .udu-right-tab-wrapper');
		if ($div.length === 0) {
			$('._').append('<div class="udu-right-tab-wrapper"></div>');
			$div = $('._ .udu-right-tab-wrapper');
		}
		return $div;
	};

	/**
	 * Returns right tab container
	 *
	 * @returns {Object}
	 */
	app.addRightTab = function(html) {
		var $div = app.getRightTabWrapper();
		var h = 0;
		$div.find('> div').each(
			function() {
				h += $(this).outerWidth() + 5;
			}
		);
		var $item = $(html).appendTo($div)
			.css('margin-top', h);

		return $item;
	};

	app.hamburger = {
			init: function(options) {
			if (!options || typeof options !== 'object') {
				options = {};
			}
			app._vars.lastWndScrollY = $(window).scrollTop();
			$(window).scroll(
				function() {
					var needFix = false;
					var pos = $(window).scrollTop();
					if (pos < 250 || pos < app._vars.lastWndScrollY) {
						needFix = true;
					}
					app._vars.lastWndScrollY = pos;

					if (needFix) {
						$('body').addClass('_mob-fix');
					} else {
						$('body').removeClass('_mob-fix');
					}
				}
			);
			var $body = $('body');
			$body.addClass('_mob-fix');

			var $hambSwitcher = $('.hamb-switcher');

			if (options.look) {
				$body.addClass('udu-hamb-look_' + options.look);
			}

			if (options.mode === 1) {
				$body.addClass('hum-mode_' + options.mode);
				$hambSwitcher.before('<li class="li-top-search mob-only">' + app.hamburger.getSearchHtml() + '</li>');
				var $addItem = $('.for-ham-top-line');
				if ($addItem.length === 1) {
					$('.top-line').append('<div class="mob-visible add-ham-html">' + $addItem.html() + '</div>');
				}
			}

			var $topLine = $('.top .top-line');
			$topLine.append('<div class="mob-logo">' + $('div.logo').html() + '</div>');

			if (options.topLineBgColor) {
				$topLine.css('background-color', options.topLineBgColor);
			}

			$hambSwitcher.mousedown(
				function() {
					var $hamburger = $('.top-hamburger');
					if ($hamburger.length === 0) {
						app.hamburger.createHamburgerMenu(options);
						setTimeout(
							function() {
								app.hamburger.switchHamburger(options);
							},
							200
						);
					} else {
						app.hamburger.switchHamburger(options);
					}
				}
			);
		},
		switchHamburger: function(options) {
			var $hambSwitcher = $('.hamb-switcher');
			var $hamburger = $('.top-hamburger');

			if ($hamburger.hasClass('opened')) {
				$hamburger.removeClass('opened');
				$hambSwitcher.find('.hamburger').removeClass('is-active');
				$('body').removeClass('_mob-ham-opened');
			} else {
				if (options.doBeforeShowMenu) {
					options.doBeforeShowMenu($hamburger);
				}
				$hamburger.addClass('opened');
				$hambSwitcher.find('.hamburger').addClass('is-active');
				$('body').addClass('_mob-ham-opened');
			}
		},
		getSearchHtml: function(options) {
			var action = $('a.lnk-search').attr('href');
			if (!action) {
				action = $('#advanced-search-form, .top .div-search-field').find('form').attr('action');
			}
			if (action) {
				if (app.getLang() === 'en') {
					var placeholder = 'Enter Product Name...';
				} else {
					placeholder = 'Entrer le nom du produit...';
				}
				return '<div class="ham-search"><form method="get" action="' + action + '"><input name="text" placeholder="' + placeholder + '" class="custom-input"><button class="custom-button" type="submit"></button></form></div>';
			} else {
				return '';
			}
		},
		buildProdList: function(params) {
			if (!params.items) {
				return '';
			}
			var html = '<div class="mob-prod-wrapper open">';
			html += '<h3>' + params.title + '</h3>';
			var limit = 15;
			html += '<div class="mob-resently-viewed">';
			var noPhoto = app.getCSSDir().replace('/css', '/img/no-photo.gif');
			for (var a in params.items) {
				limit--;
				if (limit < 0) {
					break;
				}
                var prod = params.items[a];
                try {
                    prod.url = prod.url.split('#')[0];
                } catch (e) {
                    continue;
                }

				html += '<div class="item">';
				html += '<div class="sku">' + prod.sku + '</div>';
				html += '<div class="image"><a href="' + prod.url + '"><img  onerror="this.src=\'' + noPhoto + '\'"  alt="' + prod.name + '" src="' + prod.img + '"></a></div>';
				html += '<div class="name"><a href="' + prod.url + '">' + prod.name + '</a></div>';
				html += '</div>';
			}

			if (limit === 15) {
				return '';
			}

			html += '</div></div>';
			return html;
		},
		createHamburgerMenu: function(options) {
			var html = '<div class="top-hamburger">';

			if (options.mode !== 1) {
				html += app.hamburger.getSearchHtml(options);
			}

			var uniqURLs = {};

			var tmp = ['a.logo', 'a.log-in', 'a.my-account', 'a.reg-me', 'a.log-out', 'a.currency', 'a.lang', 'a.my-cart-link, a.my-cart'];
			var mainLinksHtml = '';
			tmp.forEach(
				function (sel) {
					var $this = $(sel);
					if ($this.length > 0) {
						var href = $this.attr('href');
						var css = 'ham-fa';
						var txt = $this.html();
						var className = $this[0].className;
						if (className) {
							var tmpClassArr = className.split(' ');
							tmpClassArr.forEach(
								function (c) {
									if (c !== 'fa' && c) {
										css += ' ham-' + c;
									}
								}
							);
						}
						var addHtml = '';
						if (!uniqURLs[href] && !Number(txt)) {
							if ($this.hasClass('my-cart-link')) {
								var $tmp = $('.my-cart-count');
								if ($tmp.length === 1) {
									txt = $tmp.html();
								}
							} else if ($this.hasClass('logo')) {
								txt = 'Home';
							}
							mainLinksHtml += '<li class="' + css + '"><a data-href="' + href + '"><span>' + txt + '</span>' + addHtml + '</a></li>';
						}
						uniqURLs[href] = true;
					}
				}
			);

			if (mainLinksHtml) {
				html += '<ul class="_mob-main-ul">' + mainLinksHtml + '</ul>';
			}

			html +='<ul>';

			$('.top-line ul li:not(.n-pop):not(.mob-visible) a:not(.n-pop), .menu > ul:not(.n-pop)').each(
				function() {
					var $this = $(this);
					if ($this.get(0).nodeName === 'A') {
						var href = $(this).attr('href');
						if (!href) {
							href = '';
						}
						var txt = $this.html();
						if (!uniqURLs[href] && !Number(txt)) {
							html += '<li><a href="' + href + '">' + txt + '</a></li>';
						}
						uniqURLs[href] = true;
					} else {
						html += $this.html();
					}
				}
			);
			html += '</ul>';

			$('.d2 .li-block:not(.n-pop)').each(
				function() {
					var $this = $(this);
					var cssClass = '_mob-li-block li-block';
					if ($this.hasClass('blog-popular')) {
						cssClass += ' blog-popular';
					}
					html += '<div class="' + cssClass + '">' + $this.html() + '</div>';
				}
			);

			if (app.wishList) {
                html += this.buildProdList({
                    title: (app.getLang() === 'en' ? 'My wish list' : 'Ma liste d\'envies'),
                    items: app.getLData('__udu_wishList')
                });
			}

			var saveKey = 'udu-lastSeenProducts:' + app.storeId;
			var lastVisitProducts = app.getLData(saveKey);

			html += this.buildProdList({
				title: (app.getLang() === 'en' ? 'Recently viewed Products' : 'Derniers produits consultés'),
				items: lastVisitProducts
			});

			html += '</div>';

			html = html.replace(/ul-mega-/g, 'ul-mob-mega-');
			html += '<div class="_mob-blocker"></div>';

			var $top = $('._ > .top');
			$top.after(html);

			$('._mob-main-ul li a').click(
				function (e) {
					e.preventDefault();
					var url = $(this).data('href');
					app.blockWindow(
						true,
						true,
						function () {
							window.location = url;
						}
					)
				}
			);

			$('.mob-prod-wrapper h3').click(
				function() {
					var $div = $(this).closest('.mob-prod-wrapper');
					$div.toggleClass('open');
				}
			);

			var $mobBlock = $('._mob-li-block');

			$mobBlock.find('.level3, .level4').remove();

			for (var i = 1; i < 3; i++) {
				$mobBlock.find('ul.level' + i)
					.each(
						function() {
							$(this).attr('class', 'ul-mob-mega-' + i).closest('li').addClass('with-children');
						}
					);
			}

			setTimeout(
				function() {
					$('.ham-search input').focus();
				},
				1000
			);

			$('.top-hamburger *').removeAttr('id');
			$('.top-hamburger .tree-node').removeClass('tree-node');
			$('.top-hamburger div.node, .top-hamburger .n-pop').remove();

			$('.top-hamburger .with-children > div > a').click(
				function(e) {
					$(this).closest('li').toggleClass('opened');
					e.preventDefault();
					e.stopPropagation();
				}
			);

			return $('.top-hamburger');
		}
	};

	app.__version = function() {
		if (top !== window) {
			return false;
		}
		var colors = ['#5bcaf5', '#ffcc00', '#00d4ab', '#559cd4', '#ff7f2b', '#009482', '#f79f5b', '#d33a2a', '#559cd4', '#1b5a7c'];
		var st = 'display: inline-block; line-height: 80px; padding: 0 10px; font-size: 64px; color: white; background-color:';
		var arr = colors.map(
			function(c) {
				return st + c + ';';
			}
		);
		arr.unshift('%cU%cD%cU%cC%cA%cT%c.%cC%cO%cM');
		console.log.apply(this, arr);
		console.log('Copyright 2019 - Uducat.com. PromoBullit, Build Your Brand, Deals of the Week are trademarks of uducat.com Corporation.');
		return '';
	};

	try {
		app.__version();
	} catch(e) {

	}

	if (!String.prototype.endsWith) {
		String.prototype.endsWith = function(searchString, position) {
			var subjectString = this.toString();
			if (typeof position !== 'number' || !isFinite(position)
				|| Math.floor(position) !== position || position > subjectString.length) {
				position = subjectString.length;
			}
			position -= searchString.length;
			var lastIndex = subjectString.indexOf(searchString, position);
			return lastIndex !== -1 && lastIndex === position;
		};
	}
	if (!String.prototype.repeat) {
		String.prototype.repeat = function(count) {
			'use strict';
			if (this == null) {
				throw new TypeError('can\'t convert ' + this + ' to object');
			}
			var str = '' + this;
			count = +count;
			if (count < 0) {
				throw new RangeError('repeat count must be non-negative');
			}
			if (count == Infinity) {
				throw new RangeError('repeat count must be less than infinity');
			}
			count |= 0;
			if (str.length == 0 || count == 0) {
				return '';
			}
			if (str.length * count >= (1 << 28)) {
				throw new RangeError('repeat count must not overflow maximum string size');
			}
			while (count >>= 1) {
				str += str;
			}
			str += str.substring(0, str.length * count - str.length);
			return str;
		}
	}


if (!app.ajaxProcessURLs) {
    try {
        app.ajaxProcessURLs = app.top().app.ajaxProcessURLs;
    } catch (e) {

    }
}